import { AlcomyRoute } from '$/app/utils/routing/alcomy-route';
import { createComponentLoader } from '$/lib/create-component-loader';
import { Type } from '@angular/core';
import { Route } from '@angular/router';

/** Creates an instance of an `AlcomyRoute` */
export function createAlcomyRoute<
  TModule extends Record<string, Type<unknown>>
>(
  args: {
    componentName?: keyof TModule;
    importer?: () => Promise<TModule>;
  } & Omit<Route, 'loadComponent'>
): AlcomyRoute {
  return {
    ...args,
    path: args.path ?? '',
    loadComponent: createComponentLoader(args.componentName, args.importer)
  };
}
